import React from "react"
import { connect } from "react-redux"
import { Control, Form as ReduxForm, actions } from "react-redux-form"
import { Form, Col, Button } from "react-bootstrap"

import { ErrorList, validateEmail } from "../../components/validation"
import Loading from "../../components/loading"
import ErrorApi from "../../components/error"
import { expectAuthenticationState } from "../../components/authentication"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

class LoginForm extends React.Component {
  constructor() {
    super()
    this.state = {
      requestInProgress: false,
      requestSuccessful: false,
      requestError: false
    }
  }

  render() {
    const { requestInProgress, requestSuccessful, requestError } = this.state

    if (requestSuccessful) {
      const { email } = this.props
      return (
        <Layout>
          <SEO title="Login" />
          <h1 data-testid="check-your-inbox">
            Check your inbox{" "}
            <span
              className="icon-email"
              style={{
                verticalAlign: "middle",
                fontSize: "1.5em"
              }}
            />
          </h1>
          <p>We just emailed a login link to {email}.</p>
          <p>Click the link, you will be logged in.</p>
        </Layout>
      )
    }
    return (
      <Layout>
        <SEO title="Login" />
        <h1>You must be logged in to download the software</h1>

        {requestError && <ErrorApi />}

        <ReduxForm
          className="mt-3"
          model="loginForm.data"
          onSubmit={values => this.handleSubmit(values)}
        >
          <Form.Row>
            <Col md={10} lg={8} xl={6}>
              <Form.Group>
                <Form.Label htmlFor="email" className="is-required">
                  Email
                </Form.Label>
                <Control.text
                  type="email"
                  className="form-control"
                  model=".email"
                  id="email"
                  name="email"
                  validators={{
                    required: val => val && val.length,
                    isEmail: email => validateEmail(email || "")
                  }}
                  data-test="email"
                />
                <ErrorList
                  model=".email"
                  messages={{
                    isEmail:
                      "A valid email address is required (eg: firstname.lastname@orange.com)"
                  }}
                />
                <Form.Text className="text-muted">
                  An email will be sent containing a login link. (eg:
                  firstname.lastname@orange.com)
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
          {!requestInProgress ? (
            <Button type="submit" data-test="submit">
              Log in
            </Button>
          ) : (
            <Loading />
          )}
        </ReduxForm>
      </Layout>
    )
  }

  async handleSubmit(values) {
    this.setState({
      requestInProgress: true
    })

    const response = await fetch("/api/login/email/request", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ...values,
        webSite: window.location.origin + "/"
      })
    })
    if (response.ok) {
      this.setState({
        requestSuccessful: true,
        requestInProgress: false,
        requestError: false
      })
    } else {
      this.setState({
        requestSuccessful: false,
        requestInProgress: false,
        requestError: true
      })
      throw new Error("Cannot fetch /api/login/email/request")
    }
  }

  componentWillUnmount() {
    this.props.resetModel("loginForm.data")
  }
}

export default expectAuthenticationState({ connected: false })(
  connect(
    ({ loginForm }) => ({
      email: loginForm.data.email
    }),
    dispatch => ({
      resetModel: model => dispatch(actions.reset(model))
    })
  )(LoginForm)
)
